import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import url from 'url'

import config from '../../../utils/siteConfig'
import ArticleMeta from './ArticleMeta'
import WebsiteMeta from './WebsiteMeta'
import AuthorMeta from './AuthorMeta'

/**
* MetaData will generate all relevant meta data information incl.
* JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
*
*/

interface ScreenProps {
    data: {
        ghostPost: object,
        ghostTag: object,
        ghostAuthor: object,
        ghostPage: object,
    },
    settings: {
        allGhostSettings: { edges: Array<any> },
    },
    location: {
        pathname: string,
    },
    title: string,
    description: string,
    image: string,
}

const MetaData: React.FC<ScreenProps> = props => {
    let { data, settings, title, description, image, location } = props
    const canonical = url.resolve(config.siteUrl, location.pathname)
    const { ghostPost, ghostTag, ghostAuthor, ghostPage } = data
    const newSettings = settings ?
        settings.allGhostSettings ?
            settings.allGhostSettings.edges.length !== 0 ?
                settings.allGhostSettings.edges[0]?.node :
                null :
            null :
        null

    if (ghostPost && Object.getPrototypeOf(ghostPost) !== Object.prototype) {
        return (
            <ArticleMeta
                data={ghostPost}
                canonical={canonical}
            />
        )
    } else if (ghostTag && Object.getPrototypeOf(ghostTag) !== Object.prototype) {
        return (
            <WebsiteMeta
                data={ghostTag}
                canonical={canonical}
                type="Series"
            />
        )
    } else if (ghostAuthor && Object.getPrototypeOf(ghostAuthor) !== Object.prototype) {
        return (
            <AuthorMeta
                data={ghostAuthor}
                canonical={canonical}
            />
        )
    } else if (ghostPage && Object.getPrototypeOf(ghostPage) !== Object.prototype) {
        return (
            <WebsiteMeta
                data={ghostPage}
                canonical={canonical}
                type="WebSite"
            />
        )
    } else {
        title = title || config.siteTitleMeta || newSettings.title
        description = description || config.siteDescriptionMeta || newSettings.description
        image = image || newSettings?.cover_image || null

        image = image ? url.resolve(config.siteUrl, image) : ""

        return (
            <WebsiteMeta
                data={{}}
                canonical={canonical}
                title={title}
                description={description}
                image={image}
                type="WebSite"
            />
        )
    }
}
MetaData.defaultProps = {
    data: {
        ghostPost: {},
        ghostTag: {},
        ghostAuthor: {},
        ghostPage: {},
    },
};
const MetaDataQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettingsMetaData {
                allGhostSettings {
                    edges {
                        node {
                            title
                            description
                        }
                    }
                }
            }
        `}
        render={data => <MetaData settings={data} {...props} />}
    />
)

export default MetaDataQuery
