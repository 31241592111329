import React from "react";
import { Link } from "gatsby";

interface ScreenProps {
    data: [
        {
            label: string,
            url: string,
        }
    ]
    navClass: string,
}
const Navigation: React.FC<ScreenProps> = props => {
    const { data, navClass } = props;

    return (
        <>
            {data?.map((navItem, i) => {
                if (navItem?.url.match(/^\s?http(s?)/gi)) {
                    return (
                        <a
                            className={navClass}
                            href={navItem?.url}
                            key={i}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {navItem?.label}
                        </a>
                    );
                } else {
                    return (
                        <Link
                            className={navClass}
                            to={navItem?.url === "/" ? "/blog" : navItem?.url}
                            key={i}
                        >
                            {navItem?.label}
                        </Link>
                    );
                }
            })}
        </>
    )
};

Navigation.defaultProps = {
    navClass: `site-nav-item`,
};


export default Navigation;
