import _ from 'lodash'

export function getAuthorProperties(primaryAuthor) {
    let authorProfiles = [] as any;

    authorProfiles.push(
        primaryAuthor.website ? primaryAuthor.website : null,
        primaryAuthor.twitter ? `https://twitter.com/${primaryAuthor.twitter.replace(/^@/, ``)}/` : null,
        primaryAuthor.facebook ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, ``)}/` : null
    )

    authorProfiles = _.compact(authorProfiles)

    return {
        name: primaryAuthor.name || null,
        sameAsArray: authorProfiles.length ? `["${_.join(authorProfiles, `", "`)}"]` : null,
        image: primaryAuthor.profile_image || null,
        facebookUrl: primaryAuthor.facebook ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, ``)}/` : null,
    }
}

export default getAuthorProperties
