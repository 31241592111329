import React from 'react'
import { Link } from 'gatsby'

interface ScreenProps {
    pageContext: {
        previousPagePath: string,
        nextPagePath: string,
        humanPageNumber: string,
        numberOfPages: number
    }
}

const Pagination: React.FC<ScreenProps> = props => {
    const { pageContext } = props;
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

    return (
        <nav className="pagination" role="navigation">
            <div>
                {previousPagePath && (

                    <Link to={previousPagePath} rel="prev">
                        Previous
                    </Link>

                )}
            </div>
            {numberOfPages > 1 && <div className="pagination-location">Page {humanPageNumber} of {numberOfPages}</div>}
            <div>
                {nextPagePath && (

                    <Link to={nextPagePath} rel="next">
                        Next
                    </Link>
                )}
            </div>
        </nav>
    )
}

export default Pagination
