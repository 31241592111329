import React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'

import ImageMeta from './ImageMeta'
import getAuthorProperties from './getAuthorProperties'
import config from '../../../utils/siteConfig'

interface AuthorProps {
    data: {
        name: string,
        bio: string,
        profile_image: string,
        website: string,
        twitter: string,
        facebook: string,
    },
    settings: {
        title: string,
        twitter: string,
        description: string,
        allGhostSettings: { edges: Array<any> },
    }
    canonical: string,
}

const AuthorMeta: React.FC<AuthorProps> = props => {
    let newSettings = props.settings.allGhostSettings.edges[0].node
    const { data, canonical } = props
    const author = getAuthorProperties(data)
    const shareImage = author.image || _.get(newSettings, `cover_image`, null)
    const title = `${data?.name} - ${newSettings?.title}`
    const description = data?.bio || config?.siteDescriptionMeta || newSettings?.description

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": `Person`,
        name: data?.name,
        sameAs: author?.sameAsArray ? author?.sameAsArray : undefined,
        url: canonical,
        image: shareImage ? {
            "@type": `ImageObject`,
            url: shareImage,
            width: config?.shareImageWidth,
            height: config?.shareImageHeight,
        } : undefined,
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config?.siteUrl,
        },
        description,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
                <meta property="og:site_name" content={newSettings?.title} />
                <meta property="og:type" content="profile" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={canonical} />
                {newSettings?.twitter && <meta name="twitter:site" content={`https://twitter.com/${newSettings?.twitter.replace(/^@/, ``)}/`} />}
                {newSettings?.twitter && <meta name="twitter:creator" content={newSettings?.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

const AuthorMetaQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettingsAuthorMeta {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <AuthorMeta settings={data} {...props} />}
    />
)

export default AuthorMetaQuery
